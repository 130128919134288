<template>
    <div class="h-full">
        <div v-if="authState.isAuthed">
            <!-- <ScanCodeStickyHeader /> -->
            <div
                class="h-screen bg-gray-100 min-h-full px-4 py-16 sm:px-6 sm:py-24 md:grid md:place-items-center lg:px-8">
                <slot />

            </div>

            <footer class="flex-shrink-0 max-w-7xl w-full mx-auto px-4 sm:px-6 lg:px-8">
                <LayoutFooterPoweredByWetix />
            </footer>
        </div>
    </div>

</template>

<script setup lang="ts">
import { invoke, until } from '@vueuse/core'
// Import stores
import { useAuthState } from "~/stores/auth";
import { useGlobalAuthToken } from "~/stores/globalAuthToken";

useHead({
    title: 'WeTix Event Ticketing',
    viewport: 'width=device-width, initial-scale=1, maximum-scale=1',
    charset: 'utf-8',
    meta: [
        // { name: 'description', content: 'My amazing site.' }
    ],
    bodyAttrs: {
        class: 'h-full'
    },
    htmlAttrs: {
        class: 'h-full'
    }
})

// Nuxt app context
const { $loading, ssrContext, $firebaseAuth } = useNuxtApp()

// Global auth token
const globalAuthToken = useGlobalAuthToken()

// Auth state
const authState = useAuthState()

let authHasUser
if (!ssrContext) authHasUser = $firebaseAuth?.currentUser

// Composable to watch ID token and set cookie.
useIDTokenChange()

// On page load, we want to clear the current wetix-token cookie and only render the page once it's present.
const token = useCookie('wetix-token', {
    path: '/',
})

// console.warn(`PAGE LOAD. Token: ${token.value}`)



// If there's no token, it's expired and we should wait until it refreshes.
if (!token.value) {
    // Show the loading overlay
    // Clear the globalAuthToken, because it's reactive so we can wait for it to update.
    globalAuthToken.value = null
    invoke(async () => {
        if (!ssrContext) {
            let refreshTokenLoaderIndicator = $loading.show()

            await until(authState.isAuthed).toBeTruthy({ timeout: 5000 })
            refreshTokenLoaderIndicator.hide()
        }
    })
}

</script>